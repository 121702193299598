@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital@1&display=swap);
html,body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body {
  font-family: Montserrat , sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px) {
  .App div.background {
    background-size:cover;
    background-position-x: 5% !important;
    background-repeat: no-repeat;
  }

  .App section.content {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  
  .App section.content div.brandWrapper {
    grid-column: 1;
    grid-row: 1;
  }


  .App section.content div.buttonsWrapper {
    grid-column: 1;
    grid-row: 2;
  }
}

@media screen and (orientation:portrait) {
  .App div.background {
    background-size:cover;
    background-repeat:no-repeat;
    background-position-x: 5% !important;
    background-repeat: no-repeat;
  }

  .App section.content {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  
  .App section.content div.brandWrapper {
    grid-column: 1;
    grid-row: 1;
  }

  .App section.content div.buttonsWrapper {
    grid-column: 1;
    grid-row: 2;
  }
}

.App {
  height: 100vh;
  width: 100vw;
  text-align: center;
  align-items: center;
  justify-items: center;
  overflow: hidden;
}

.App div.background {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(/static/media/bgBlur.c5d3ebe5.png);
  background-position: center;
  height: 100%;
  width: 100%;
}

.App .content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-rows: 100%;
  grid-template-columns: 2fr 1fr 1fr 2fr;
  grid-column-gap: 10rem;
  -webkit-column-gap: 10rem;
          column-gap: 10rem;
}

.App section.content .brandWrapper {
  grid-row: 1 / span 2;
  grid-column: 2;
  display: grid;
  align-items: center;
  width: 100%;
  justify-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.App section.content img.logo {
  max-height: 25vh;
  -webkit-filter: drop-shadow(5px 5px 2px );
          filter: drop-shadow(5px 5px 2px );
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App section.content div.textWrapper {
  color: #FFF;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App section.content div.textWrapper h1 {
  font-size: 2.5rem;
  margin: 0;
}

.App section.content div.textWrapper h2 {
  margin: 0.25rem;
}

.App section.content .buttonsWrapper {
  grid-row: 1 / span 2;
  grid-column: 3;
  align-items: center;
  justify-items: center;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  gap: 2rem;
}

.App section.content div.buttonsWrapper div.btn {
  font-weight: bold;
  cursor: pointer;
  background-color: #FFF;
  padding: 1rem 1.5rem 1rem 1.5rem;
  font-size: 1.2rem;
  -webkit-filter: drop-shadow(4px 4px 1px );
          filter: drop-shadow(4px 4px 1px );
  border-radius: 2px;
}

.App section.content div.buttonsWrapper a {
  text-decoration: none;
  color: #252525;
}

.App section.content div.buttonsWrapper div.btn:hover {
  background-color: rgb(167, 167, 167);
}


